import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const reportsMenu = {
    path: "/reports",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.reports")
    },
    children: [
        {
            path: "activities",
            name: "ReportsActivities",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VActivities"
                ),
            meta: {
                title: i18n.t("title.reports_hour_report")
            }
        },
        {
            path: "absence",
            name: "ReportsAbsence",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VAbsence"
                ),
            meta: {
                title: i18n.t("title.reports_absence")
            }
        },
        {
            path: "projects",
            name: "ReportsProjects",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VProjects"
                ),
            meta: {
                title: i18n.t("title.reports_projects")
            }
        },
        {
            path: "projects-performance",
            name: "ReportsProjectsPerformance",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VProjectsPerformance"
                ),
            meta: {
                title: i18n.t("title.reports_projects_performance")
            }
        },
        {
            path: "admin-projects-performance",
            name: "ReportsAdminProjectsPerformance",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VAdminProjectsPerformance"
                ),
            meta: {
                title: i18n.t("title.reports_admin_projects_performance")
            }
        },
        {
            path: "open-days",
            name: "ReportsOpenDays",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VOpenDays"
                ),
            meta: {
                title: i18n.t("title.open_days")
            }
        },
        {
            path: "personal-multiview",
            name: "ReportsPersonalMultiview",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VPersonalMultiview"
                ),
            meta: {
                title: i18n.t("title.personal_multiview")
            }
        },
        {
            path: "my-hours",
            name: "ReportsMyReport",
            component: () =>
                import(
                    /* webpackChunkName "reports" */ "@/views/Reports/VMyReport"
                ),
            meta: {
                title: i18n.t("title.my_report")
            }
        }
    ]
};

export default reportsMenu;